.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.project-card-buttons {
  display: flex;
  justify-content: space-around;
  padding: 0.5em 0;
}

.project-card-buttons .btn {
  padding: 0.5em 1.2em;
}

.project-card-view .card-img-top {
  padding: 20px;
  opacity: 0.8;
  border-radius: 10px;
}

.project-card-view h5 {
  padding: 0.5em 0;
}

.project-card-view p {
  padding-bottom: 1em;
} 