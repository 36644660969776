.language-switcher {
  position: fixed;
  top: 100px;
  right: 20px;
  background: #ff7b00;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  z-index: 999;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  pointer-events: auto;
  user-select: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.language-switcher:hover {
  background: #ffae00;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 123, 0, 0.2);
}

.language-switcher:active {
  transform: translateY(0);
}

.language-switcher img {
  width: 20px;
  height: auto;
  vertical-align: middle;
  pointer-events: none;
}

@media (max-width: 768px) {
  .language-switcher {
    top: 80px;
    right: 15px;
    padding: 6px 12px;
    font-size: 0.9rem;
  }
} 