.registration-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.registration-form h2 {
  text-align: center;
  color: purple;
}

.registration-form label {
  display: block;
  margin: 10px 0 5px;
  color: purple;
}

.registration-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.registration-form button {
  width: 100%;
  padding: 10px;
  background-color: purple;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.registration-form button:hover {
  background-color: #5a2d8a;
}
