.blog-card {
  padding: 20px;
  margin-bottom: 30px;
}

.blog-card-view {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 10px;
  color: white;
  transition: transform 0.3s ease;
}

.blog-card-view:hover {
  transform: scale(1.02);
}

.blog-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.blog-tags {
  margin: 15px 0;
}

.blog-tag {
  background: #ff7b00;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 10px;
  font-size: 0.8rem;
}

.blog-card-view h3 {
  color: #ff7b00;
  margin: 10px 0;
}

.blog-card-view p {
  color: #e0e0e0;
  margin: 10px 0;
}

.blog-card-view .btn {
  background: #ff7b00;
  border: none;
  margin-top: 15px;
}

.blog-card-view .btn:hover {
  background: #ff9f40;
}

.blog-post {
  background: rgba(255, 255, 255, 0.05) !important;
  border: none !important;
  color: white !important;
  margin-bottom: 2rem;
  transition: transform 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-5px);
}

.blog-meta {
  color: #8b8b8b;
  font-size: 0.9rem;
  margin: 0.5rem 0 1rem;
  display: flex;
  gap: 1rem;
}

.blog-meta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.blog-tags {
  margin-top: 1rem;
}

.blog-tags .badge {
  background: #ff7b00 !important;
  font-weight: normal;
  padding: 0.5em 1em;
} 