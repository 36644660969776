.services-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.services-intro {
  text-align: center;
  margin-bottom: 2rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.service-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.service-title {
  color: #ff7b00;
  font-size: 1.8rem;
  margin: 15px 0;
}

.service-description {
  color: #e0e0e0;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.service-tiers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.service-tier {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 25px;
  text-align: center;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tier-name {
  color: #fccb99;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.tier-price {
  color: #ff7b00;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 15px 0;
}

.tier-features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  color: #e0e0e0;
}

.tier-feature {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.4;
}

.select-button[aria-label*="Black Tier"],
.select-button[aria-label*="Tier Black"],
.select-button[aria-label*="Niveau Black"] {
  background: #000000;
  color: white;
  border: 1px solid #404040;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.select-button[aria-label*="Gold Tier"],
.select-button[aria-label*="Tier Gold"],
.select-button[aria-label*="Niveau Gold"] {
  background: #ffd700;
  color: #000000;
  border: 2px solid #ffed4a;
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.15);
}

/* Base styles for all select buttons */
.select-button {
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  margin-top: 15px;
  width: auto;
  display: inline-block;
  min-width: 160px;
}

.select-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 123, 0, 0.25);
}

.select-button:focus {
  outline: 2px solid #ff7b00;
  outline-offset: 2px;
}

@media (max-width: 768px) {
  .service-tiers {
    grid-template-columns: 1fr;
  }
  
  .service-title {
    font-size: 1.5rem;
  }
  
  .service-description {
    font-size: 1rem;
  }
}

.service-card:hover {
  transform: translateY(-5px);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.service-card {
  animation: fadeIn 0.5s ease-in;
}

.phone-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1a1a;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.modal-content h3 {
  color: #ff7b00;
  margin-bottom: 1.5rem;
}

.confirmation-message p {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.tier-features {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tier-features li {
  padding: 5px 0;
}

.project-heading {
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.project-heading .purple {
  font-size: 3.5rem;
  color: #ff7b00;
}

.services-subtitle {
  font-size: 1.3rem;
  text-align: center;
  color: #8b8b8b;
}

/* Success Modal Styles */
.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.success-content {
  background: #1a1a1a;
  padding: 3rem;
  border-radius: 15px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  border: 2px solid #ff7b00;
  box-shadow: 0 0 30px rgba(255, 123, 0, 0.2);
  animation: slideUp 0.4s ease-out;
}

.success-content h3 {
  color: #ff7b00;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.success-content p {
  color: #ffffff;
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.loading-spinner {
  margin: 2rem auto;
  width: 40px;
  height: 40px;
  border: 3px solid #ff7b00;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
} 

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  color: white;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 